<p-dropdown
    #lov
    *ngIf="!isMulti"
    [id]="id"
    [(ngModel)]="selectedData"
    (onChange)="onChange($event.value)"
    [options]="list"
    [disabled]="disabled"
    [filter]="filter"
    [readonly]="readonly"
    [showClear]="showClear"
    (onClear)="onClear($event)"
    [optionLabel]="optLabel"
    [filterBy]="optLabel"
    placeholder="{{ label }}"
    [appendTo]="appendTo"
    [ngClass]="ngClass"
    dropdownIcon="fas fa-caret-down"
    [styleClass]="styleClass"
>
    <!-- for Prefix Front Label -->
    <ng-template *ngIf="prefixFrontLabel" let-item pTemplate="selectedItem">
        <span>{{ prefixFrontLabel || "" }}</span>
        <span [ngClass]="{ 'font-bold': isBoldValueLabelPrefix }">
            {{ item[optLabel] }}
        </span>
    </ng-template>

    <ng-container *ngIf="isPeriodDetailWithSequence">
        <ng-template pTemplate="selectedItem">
            {{ formatPeriodDetailWithSequence(selectedData) }}
        </ng-template>

        <ng-template pTemplate="item" let-data>
            <span class="is-tax-object--element">
                {{ formatPeriodDetailWithSequence(data) }}
            </span>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="isPeriodCW">
        <ng-template pTemplate="selectedItem">
            <span>
                {{ selectedData?.periodYear }}
                {{
                    selectedData?.lovPeriodTypeName
                        .toLowerCase()
                        .includes("year")
                        ? ""
                        : " - " + selectedData?.lovPeriodTypeName
                }}
                {{
                    isPeriodCWDetailed ? contentPeriodDetail(selectedData) : ""
                }}
            </span>
        </ng-template>

        <ng-template let-data pTemplate="item">
            <span class="is-tax-object--element">
                {{ data?.periodYear }}
                {{
                    data?.lovPeriodTypeName.toLowerCase().includes("year")
                        ? ""
                        : " - " + data?.lovPeriodTypeName
                }}
                {{ isPeriodCWDetailed ? contentPeriodDetail(data) : "" }}
            </span>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="isTaxObject">
        <ng-template pTemplate="selectedItem">
            <span> ({{ selectedData?.code }}) {{ selectedData?.name }} </span>
        </ng-template>

        <ng-template let-data pTemplate="item">
            <span class="is-tax-object--element">
                ({{ data?.code }}) {{ data?.name | truncate : truncate }}
            </span>
        </ng-template>
    </ng-container>

    <ng-template pTemplate="empty">
        <p-progressBar
            [style]="{ height: '4px' }"
            mode="indeterminate"
            *ngIf="loading; else normal"
        ></p-progressBar>
    </ng-template>

    <ng-template *ngIf="isCreateMovementSetting" pTemplate="footer">
        <div class="p-2">
            <a
                href="/admin/workforce/settings/movement-type/list"
                target="_blank"
                style="cursor: pointer"
            >
                <div
                    class="flex px-2 py-3 text-blue align-items-center"
                    style="gap: 0.5rem"
                >
                    <i class="fa-solid fa-plus"></i>
                    <span>Create Movement Settings</span>
                </div>
            </a>
        </div>
    </ng-template>
    
    <ng-container *ngIf="isUseCompanyCode">
        <ng-template let-data pTemplate="item">
            <span *ngIf="data?.companyCode">{{data?.companyCode}} - {{data?.name}}</span>
            <span *ngIf="!data?.companyCode">{{data?.name}}</span>
        </ng-template>
    </ng-container>
    
</p-dropdown>

<p-multiSelect
    #multiLovs
    *ngIf="isMulti"
    [(ngModel)]="selectedDatas"
    [showToggleAll]="showToggleAll"
    (onChange)="onChange($event.value)"
    [options]="list"
    [disabled]="disabled"
    [filter]="filter"
    [readonly]="readonly"
    [optionLabel]="optLabel"
    placeholder="{{ label }}"
    [ngClass]="ngClass"
    [display]="useChip ? 'chip' : 'comma'"
    [appendTo]="appendTo"
    dropdownIcon="fas fa-caret-down"
    [styleClass]="styleClass"
    (onPanelHide)="onHide()"
>
    <ng-container *ngIf="customOptsSelectedWorksheet">
        <ng-template let-value pTemplate="selectedItems">
            <span *ngIf="value?.length > 0; else defaultLabel" class="text-grey"
                >{{ value?.length }} Task(s)</span
            >
            <ng-template #defaultLabel>
                <span class="text-grey">Select task realized</span>
            </ng-template>
        </ng-template>

        <ng-template let-data pTemplate="item">
            <div
                class="flex flex-column align-items-start justify-content-center ml-2"
                style="gap: 0.4rem"
            >
                <span *ngIf="data?.isCarriedOver" class="text-red"
                    >Not Carried</span
                >
                <span *ngIf="!data?.isCarriedOver" class="text-green"
                    >Carried</span
                >

                <span class="text-grey text-base">{{ data?.taskName }}</span>
            </div>
        </ng-template>
    </ng-container>

    <ng-template let-country pTemplate="empty">
        <p-progressBar
            [style]="{ height: '4px' }"
            mode="indeterminate"
            *ngIf="loading; else normal"
        ></p-progressBar>
    </ng-template>

    <ng-container *ngIf="isUseCompanyCode">
        <ng-template let-data pTemplate="item">
            <span *ngIf="data?.companyCode">{{data?.companyCode}} - {{data?.name}}</span>
            <span *ngIf="!data?.companyCode">{{data?.name}}</span>
        </ng-template>
    </ng-container>
    
</p-multiSelect>

<ng-template #normal>No Data</ng-template>
