import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AppConstant } from '@core/config/app.config';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
    selector: 'app-error-page-forbidden-found',
    styleUrls: ['./page-forbidden.component.scss'],
    templateUrl: 'page-forbidden.component.html',
})
export class PageForbiddenComponent {
    private _web: string = localStorage.getItem('web');

    private get isSuperAdmin(): boolean {
        return (
            this._authenticationService.getLocalStorage(AppConstant.userSession)
                ?.roleType === 'SUPERADMIN'
        );
    }

    constructor(
        private readonly _router: Router,
        private readonly _authenticationService: AuthenticationService
    ) {}

    public _getData(): {
        token: string | undefined;
        hasAccess: boolean;
        hasEssAccess: boolean;
        hasMssAccess: boolean;
        hasAdminAccess: boolean;
    } {
        const info =
            this._authenticationService.getLocalStorage(AppConstant.info) || {};

        const token = this._authenticationService.getSession()?.accessToken;
        const accessEss = this._authenticationService.getLocalStorage(
            AppConstant.accessEss
        );
        const accessMss = this._authenticationService.getLocalStorage(
            AppConstant.accessMss
        );
        const accessAdmin = this._authenticationService.getLocalStorage(
            AppConstant.accessAdmin
        );

        return {
            token,
            hasEssAccess: info.hasEssAccess || false,
            hasMssAccess: info.hasMssAccess || false,
            hasAdminAccess: info.hasAdminAccess || false,
            hasAccess: Boolean(accessEss || accessMss || accessAdmin),
        };
    }

    public returnToHome(): void {
        const { token, hasAccess, hasEssAccess, hasMssAccess, hasAdminAccess } =
            this._getData();

        if (hasEssAccess && !this.isSuperAdmin) {
            this._web = 'ess';
        } else if (hasMssAccess && !this.isSuperAdmin) {
            this._web = 'mss';
        } else {
            this._web = hasAdminAccess ? 'admin' : '';
        }

        let url = this._web ? `${this._web}/` : '/login';

        if (
            (token &&
                !hasAccess &&
                !this._authenticationService.isLoggedIn()) ||
            (!hasAdminAccess && !hasEssAccess && !hasMssAccess)
        ) {
            url = '/login';
            this._authenticationService.destroySession();
        }

        this._router.navigate([url]);
    }
}
